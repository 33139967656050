import React, { useState } from 'react';

import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';

const DropdownCard = ({ heading, list, active }) => {
  const [isActive, setIsActive] = useState(active || false);

  return (
    <div className="dropdown__card">
      <div className="dropdown__card__heading" onClick={() => setIsActive(!isActive)}>
        <h4>{heading}</h4>
        {list && (
          <button onClick={() => setIsActive(!isActive)} style={{ backgroundColor: isActive ? '#0D3F74' : '#FF7624' }}>
            {isActive ? <AiOutlineMinus /> : <AiOutlinePlus />}
          </button>
        )}
      </div>
      {isActive && list && (
        <ul>
          {list
            .filter(p => p !== '')
            .map(element => (
              <li key={element}>{element}</li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownCard;
