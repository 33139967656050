import React, { useState } from 'react';

import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';

const Dropdown = ({ heading, active, children }) => {
  const [isActive, setIsActive] = useState(active || false);

  return (
    <div className="dropdown__container">
      <div className="dropdown__heading" onClick={() => setIsActive(!isActive)}>
        <h3>{heading}</h3>
        <span>{isActive ? <AiOutlineMinus /> : <AiOutlinePlus />}</span>
      </div>
      {isActive && children}
    </div>
  );
};

export default Dropdown;
