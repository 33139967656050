import React from 'react';

import { Link } from 'gatsby';
import sanitizeHtml from 'sanitize-html';

const Subscription = ({ mainText, basic, premium, button, to, linkState }) => {
  return (
    <section className="subscription">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto subscription__container">
            <div className="subscription__text">
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(mainText),
                }}
              />
              <Link to={to} state={linkState} className="btn primary-btn subscription__link desktop-only">
                {button || 'Solicitar información'}
              </Link>
            </div>

            <div className="subscription__cards">
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(basic),
                }}
              />

              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(premium),
                }}
              />
            </div>

            <Link to={to} state={linkState} className="btn primary-btn subscription__link mobile-only">
              {button || 'Solicitar información'}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscription;
