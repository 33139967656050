import React, { useRef } from 'react';

import SEO from '../components/seo';
import { Link, graphql } from 'gatsby';
import { Layout } from '../layout/Layout';
import { Dropdown, DropdownCard, Subscription } from '../components/modules/research';
import { BannerWithBackground as Banner, Description, Details, Publications } from '../components/common';
import Benefits from '../components/modules/evaluaciones/Benefits';
import { useQueryParam } from 'gatsby-query-params';
import useDragScroll from 'use-drag-scroll';
import { clamp } from '../utils/helpers';

const relatedPostsTitle = {
  'en-US': 'Latest related posts',
  'es-ES': 'Últimas publicaciones relacionadas',
  'fr-FR': 'Dernières publications connexes',
  'pt-BR': 'Últimas publicações relacionadas.',
};

export const query = graphql`
  query MicroRateResearchQuery {
    site {
      siteMetadata {
        siteUrl
        cmsUrl
      }
    }
  }
`;

const MicroRateResearch = ({ data, pageContext }) => {
  const { banner_image_mobile, banner_image } = pageContext.data;
  const { top_banner_text, subscription_text, subscription_basic, subscription_premium, subscription_button } =
    pageContext.translation;

  const content = pageContext.content.map(element => {
    return {
      image: element.image,
      financial_countries: element.financial_countries,
      social_countries: element.social_countries,
      spanishTitle: element.translations.filter(t => t.languages_code.code === 'es-ES')[0].title,
      ...element.translations.filter(t => t.languages_code.code === pageContext.languageCode)[0],
    };
  });

  let contentIndex = useQueryParam('tab', 1);
  contentIndex = parseInt(contentIndex) || 1;
  contentIndex = clamp(contentIndex, 1, content.length);
  const pageContent = content[contentIndex - 1];
  const cmsURL = data.site.siteMetadata.cmsUrl;

  // Scroll on tabs
  const selectSliderRef = useRef();
  useDragScroll({
    sliderRef: selectSliderRef,
  });

  return (
    <Layout pageId="research" currentLanguage={pageContext.languageCode}>
      <Banner
        innerText={top_banner_text}
        desktopImage={`${cmsURL}/assets/${banner_image.id}`}
        mobileImage={`${cmsURL}/assets/${banner_image_mobile.id}`}
      />

      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div ref={selectSliderRef} className="select-slider no-scrollbar">
              {content.map((pageInfo, index) => {
                return (
                  <Link
                    key={pageInfo.title}
                    className={`btn ${parseInt(contentIndex) === index + 1 ? 'active' : ''}`}
                    to={`?tab=${index + 1}`}
                  >
                    {pageInfo.short_title || pageInfo.title}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Description
        heading={pageContent.title}
        body={pageContent.description}
        image={`${cmsURL}/assets/${pageContent.image.id}`}
      />
      {pageContent.details && <Details content={pageContent.details} />}
      {pageContent.benefits_text && (
        <Benefits
          content={pageContent.benefits_text}
          link={`${pageContext.basePath}/contact`}
          linkState={{ subject: pageContent.spanishTitle }}
          buttonText={pageContent.benefits_button}
        />
      )}

      {pageContent.financial_title && pageContent.social_title ? (
        <section className="dropdown">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <Dropdown heading={pageContent.financial_title || 'Edición financiera'} active={true}>
                  {pageContent.financial_description
                    ?.split('\n')
                    .filter(p => p !== '')
                    .map(p => (
                      <p key={p}>{p}</p>
                    ))}

                  {pageContent.financial_cards && (
                    <div className="dropdown__cards">
                      <div className="dropdown__cards__container">
                        {pageContent.financial_cards
                          .slice(0, Math.ceil(pageContent.financial_cards.length / 2))
                          .map(card => (
                            <DropdownCard key={card.title} heading={card.title} list={card.body?.split('\n')} />
                          ))}
                      </div>
                      <div className="dropdown__cards__container">
                        {pageContent.financial_cards
                          .slice(-Math.ceil(pageContent.financial_cards.length / 2))
                          .map(card => (
                            <DropdownCard key={card.title} heading={card.title} list={card.body?.split('\n')} />
                          ))}
                      </div>
                    </div>
                  )}

                  {pageContent.financial_countries_description
                    ?.split('\n')
                    .filter(p => p !== '')
                    .map(p => (
                      <p key={p}>{p}</p>
                    ))}

                  {pageContent.financial_countries && (
                    <ul className="country-list">
                      {pageContent.financial_countries.map(country => (
                        <li>
                          <span>
                            <img src={`${cmsURL}/assets/${country.flag.id}`} />
                          </span>
                          {country.name}
                        </li>
                      ))}
                    </ul>
                  )}

                  {pageContent.financial_button && (
                    <Link
                      to={`${pageContext.basePath}/contact`}
                      state={{ subject: pageContent.spanishTitle }}
                      className="btn primary-btn"
                    >
                      {pageContent.financial_button}
                    </Link>
                  )}
                </Dropdown>
                <Dropdown heading={pageContent.social_title || 'Edición social'}>
                  {pageContent.social_description
                    ?.split('\n')
                    .filter(p => p !== '')
                    .map(p => (
                      <p key={p}>{p}</p>
                    ))}

                  {pageContent.social_cards && (
                    <div className="dropdown__cards">
                      <div className="dropdown__cards__container">
                        {pageContent.social_cards.slice(0, Math.ceil(pageContent.social_cards.length / 2)).map(card => (
                          <DropdownCard key={card.title} heading={card.title} list={card.body.split('\n')} />
                        ))}
                      </div>
                      <div className="dropdown__cards__container">
                        {pageContent.social_cards.slice(-Math.floor(pageContent.social_cards.length / 2)).map(card => (
                          <DropdownCard key={card.title} heading={card.title} list={card.body.split('\n')} />
                        ))}
                      </div>
                    </div>
                  )}

                  {pageContent.social_countries_description
                    ?.split('\n')
                    .filter(p => p !== '')
                    .map(p => (
                      <p key={p}>{p}</p>
                    ))}

                  {pageContent.social_countries && (
                    <ul className="country-list">
                      {pageContent.social_countries.map(country => (
                        <li>
                          <span>
                            <img src={`${cmsURL}/assets/${country.flag.id}`} />
                          </span>
                          {country.name}
                        </li>
                      ))}
                    </ul>
                  )}

                  {pageContent.social_button && (
                    <Link
                      to={`${pageContext.basePath}/contact`}
                      className="btn primary-btn"
                      state={{ subject: pageContent.spanishTitle }}
                    >
                      {pageContent.social_button}
                    </Link>
                  )}
                </Dropdown>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <Subscription
        mainText={subscription_text}
        basic={subscription_basic}
        premium={subscription_premium}
        button={subscription_button}
        to={`${pageContext.basePath}/contact`}
        linkState={{ subject: 'Suscripción para inversionistas y académicos' }}
      />
      <Publications currentLanguage={pageContext.languageCode}>
        <h2>
          <strong>{relatedPostsTitle[pageContext.languageCode]}</strong>
        </h2>
      </Publications>
    </Layout>
  );
};

export default MicroRateResearch;

export const Head = () => <SEO title="Research - MicroRate" />;
