import React from 'react';

import { Link } from 'gatsby';
import sanitizeHtml from 'sanitize-html';
import { AiOutlineStar } from 'react-icons/ai';

const Benefits = ({
  link,
  linkState,
  content,
  children,
  buttonText,
  benefitsMessage,
}) => {
  return (
    <section className="benefits">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto benefits__container">
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content),
              }}
            />
            {children}
            {link && (
              <Link
                className="btn primary-btn benefits__link"
                to={link}
                state={linkState}
              >
                {buttonText}
              </Link>
            )}
            {benefitsMessage && (
              <p className="benefits__msg">
                <span className="icon">
                  <AiOutlineStar />
                </span>
                <span>{benefitsMessage}</span>
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
